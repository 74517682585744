.contractTitle {
    mso-style-name: "Document Title";
    mso-style-priority: 99;
    mso-style-unhide: no;
    mso-style-next: "Body Text";
    margin-top: 0cm;
    margin-right: 0cm;
    margin-left: 0cm;
    text-align: center;
    mso-pagination: widow-orphan;
    font-size: 12.0pt;
    font-family: "Times New Roman", serif;
    mso-fareast-font-family: "Times New Roman";
    text-transform: uppercase;
    mso-ansi-language: EN-US;
    mso-fareast-language: EN-US;
    mso-bidi-font-weight: normal;
}

.content {
    mso-style-unhide: no;
    mso-style-qformat: yes;
    mso-style-parent: "";
    margin: 0cm;
    margin-bottom: .0001pt;
    mso-pagination: widow-orphan;
    font-size: 12.0pt;
    font-family: "Times New Roman", serif;
    mso-fareast-font-family: "Times New Roman";
    mso-ansi-language: EN-US;
    mso-fareast-language: EN-US;
}

.variables {
    color: #2F5496;
    mso-themecolor: accent1;
    mso-themeshade: 191
}

ol.numbers {
    counter-reset: counter1;
}

ol.numbers li::before {
    content: counter(counter1);
    counter-increment: counter1;
}

ol.lower-alpha {
    counter-reset: counter2;
}

ol.lower-alpha li {
    display: flex;
    gap: 10px;
}

ol.lower-alpha li::before {
    content: "(" counter(counter2, lower-alpha)")";
    counter-increment: counter2;
}

ol.none {
    counter-reset: counter3;
}

ol.none li {
    display: flex;
    gap: 26px;
}

ol.none li::before {
    content: "";
    counter-increment: counter3;
}