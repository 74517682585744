@tailwind base;
@tailwind components;
@tailwind utilities;

/* :root {
  font-size: 18px; 
} */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background-color: #F9FAFB;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-radio input:checked {
  display: none;
}

.custom-radio input:checked+i {
  display: inline;
  color: #3F83F8;
}

.rotate {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

.subHeadingAlignment{
  padding-left: 2rem;
}

.subRadioAlignment{
  padding-left: 1.5rem;
}

.subSectionAlignment{
  padding-left: 2.2rem;
}

.customSidebarBG{
  background-image: linear-gradient(45deg,hsla(246,16%,26%,0.04),hsla(246,16%,26%,0.04));
}

.radiogridLayout{
  display: grid !important;
  grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
}

.swal2-html-container {
  font-size: 0.9rem !important;
}

@media (max-width: 900px) {
  .radiogridLayout{
    display: block !important;
  }
 }